<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>基础设置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/giftSet' }"
          >礼物赠送配置
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="200px"
        size="mini"
      >
        <el-form-item
          label="礼品选择："
          label-position="left"
          prop="gift_credit_template_id"
        >
          <gift :get-gift-id="true" @success="success" />
          <div v-if="giftData.name">
            <span class="margin-r-sm">礼品名称：{{ giftData.name }}</span>
            <span>赠送学币：{{ giftData.credit }}</span>
          </div>
        </el-form-item>
        <el-form-item
          label="开始累计打卡天数："
          label-position="left"
          prop="start_sign_day"
        >
          <el-input-number
            v-model="form.start_sign_day"
            :min="0"
            label="开始打卡天数"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="截止累计打卡天数："
          label-position="left"
          prop="end_sign_day"
        >
          <el-input-number
            v-model="form.end_sign_day"
            :min="form.start_sign_day"
            label="截止打卡天数"
          ></el-input-number>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="$router.go(-1)"
            >取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import gift from "../../../components/gift/index";

export default {
  name: "add",
  components: {
    gift,
  },
  data() {
    return {
      loading: false,
      form: {
        gift_credit_template_id: "",
        start_sign_day: 0,
        end_sign_day: 0,
      },
      giftData: {},
      rules: {
        gift_credit_template_id: [
          { required: true, message: "请选择礼品", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("rule", ["addRule"]),
    success(data) {
      this.form.gift_credit_template_id = data.id;
      this.giftData = data;
    },
    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const { res_info } = await this.addRule(this.form);
            if (res_info !== "ok") return;
            await this.$router.push("/giftSet");
            this.$message.success("创建成功");
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>